import React, { useEffect, useState, Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import store from 'store2';

import { Button } from '@peakon/bedrock/react/button';
import { Modal } from '@peakon/bedrock/react/dialog';
import { ButtonLink } from '@peakon/bedrock/react/link';
import { t } from '@peakon/shared/features/i18next/t';
import { replaceWindowLocation } from '@peakon/shared/utils/window';

import { useShellContext } from '../../context/ShellContext';

import styles from './styles.css';

const INTERVAL = 5 * 60 * 1000; // 5 minutes

export const SystemNewVersionBar = () => {
  const history = useHistory();
  const { version, onNewVersion, features } = useShellContext();
  const [newVersion, setNewVersion] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const disableNewVersionModal = features.includes('disableNewVersionModal');

  const handleReload = () => {
    window.location.reload();
  };

  const handleVisibilityChange = () => {
    if (!isModalOpen && !window.document.hidden) {
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);

    window.document.removeEventListener(
      'visibilitychange',
      handleVisibilityChange,
    );
  };

  useEffect(() => {
    return history.listen(({ pathname, search }) => {
      if (disableNewVersionModal && newVersion) {
        const newUrl = new URL(`${pathname}${search}`, window.location.origin);
        replaceWindowLocation(newUrl.href);
      }
    });
  }, [history, newVersion, disableNewVersionModal]);

  function handleVersionChange(newVersionFromResponse: string) {
    setNewVersion(newVersionFromResponse);
    onNewVersion();

    if (!disableNewVersionModal) {
      window.document.addEventListener(
        'visibilitychange',
        handleVisibilityChange,
      );
    }
  }

  useEffect(() => {
    if (!version) {
      return;
    }

    const disableVersionChecker = store.local.get('disable_version_checker');
    if (disableVersionChecker) {
      return;
    }

    async function checkVersion() {
      try {
        const response = await fetch('/source-version');
        const newVersionFromResponse = await response.text();

        if (
          newVersionFromResponse.length > 0 &&
          newVersionFromResponse !== version
        ) {
          handleVersionChange(newVersionFromResponse);
        }
      } catch {
        // noop
      }
    }

    const poller = setInterval(checkVersion, INTERVAL);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkVersion();

    return () => {
      clearInterval(poller);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  if (!newVersion) {
    return null;
  }

  return (
    <Fragment>
      {/* NOTE: custom styling because BodyText does not support inverted colors */}
      <p className={styles.description}>{t('new_source_version')}</p>
      <ButtonLink onClick={handleReload}>
        {t('new_source_version__refresh')}
      </ButtonLink>
      <Modal
        heading={t('new_version_modal__text', {
          replace: { emphasis_start: '', emphasis_end: '' },
        })}
        open={isModalOpen}
        onDismiss={handleModalClose}
        closeLabel={t('common__close')}
        data-test-id="new-version-modal"
      >
        <Modal.Actions>
          <Button variant="primary" onClick={handleReload}>
            {t('new_version_modal__refresh_to_update')}
          </Button>
          <Button variant="secondary" onClick={handleModalClose}>
            {t('new_version_modal__not_now')}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};
